var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-list-item-content',{staticClass:"py-0",staticStyle:{"align-self":"center"}},[_c('v-list-item-title',{staticClass:"text-capitalize d-flex align-center",staticStyle:{"font-size":"14px"}},[_c('hb-icon',{staticClass:"pr-1",staticStyle:{"position":"relative","top":"-1px"},attrs:{"color":"#101318","small":""}},[_vm._v("mdi-account")]),_c('span',{staticClass:"hb-font-body-medium hb-text-night mx-1"},[_vm._v(_vm._s(_vm.contact.first)+" "+_vm._s(_vm.contact.last))]),_vm._v("  "),(_vm.contact.status)?_c('hb-status',[_vm._v(_vm._s(_vm.contact.status))]):_vm._e(),_vm._v("  "),(this.displayContactBalance)?_c('span',{staticStyle:{"color":"#637381"}},[_vm._v(" (Balance "+_vm._s(_vm._f("formatMoney")(_vm.balanceDue))+")")]):_vm._e()],1),_c('v-list-item-subtitle',[(
          _vm.contact.Phones && _vm.contact.Phones.length > 0
        )?_c('span',[_vm._v(_vm._s(_vm._f("formatPhone")(_vm.contact.Phones[0].phone)))]):_vm._e(),(
          _vm.contact.Phones &&
          _vm.contact.Phones.length > 0 &&
          _vm.contact.email
        )?_c('span',[_vm._v(" | ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.contact.email))])]),_vm._l((_vm.leases),function(lease,index){return _c('span',{key:'lease' + index},[(lease && lease.id)?_c('div',[_c('v-list-item-subtitle',{staticClass:"contact-space-pointer d-flex align-center",on:{"click":function($event){return _vm.viewLease(_vm.r)}}},[_c('hb-icon',{attrs:{"small":"","type":_vm.getUnitTypeLabel(lease.Unit),"applyStroke":false}}),_c('span',{staticClass:"pl-1"},[_vm._v(" "+_vm._s(lease.Unit.number)+" | "),(lease.Unit.Property.number)?_c('span',[_vm._v(_vm._s(lease.Unit.Property.number)+" - ")]):_vm._e(),_vm._v(_vm._s(_vm._f("formatAddress")(lease.Unit.Property.Address,'-', true))+" ")])],1)],1):_vm._e()])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }