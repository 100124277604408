<template>
    <div>
        <hb-modal :title="isBusinessProfile ? TENANT.TENANT_PROFILE.MODAL_TITLE : TENANT.BUSINESS_PROFILE.MODAL_TITLE" v-model="dialog"
            @close="$emit('close')">
            <template v-slot:subheader>
                {{ isBusinessProfile ? TENANT.TENANT_PROFILE.DESC : TENANT.BUSINESS_PROFILE.DESC }}
            </template>

            <template v-slot:content>
                <hb-form :label="isBusinessProfile ? 'Email' : 'Business Email'" required>
                    <HbTextField v-model="profileData.email" v-validate="{ required: true, max:45, regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ }"
                        :placeholder="'Enter Email'" name="contact-email" id="contact-email" data-vv-scope="general"
                        data-vv-name="email" :data-vv-as="'Email'" autocomplete="cc-contact-email"
                        :error="errors.collect('general.email').length > 0" />
                </hb-form>

                <hb-form v-for="(phone, i) in profileData.Phones" :key="'phone_' + i"
                    :label="profileData.Phones.length > 1 ? 'Phone ' + (i + 1) : 'Phone'" required full>
                    <div class="d-flex align-top">
                        <v-col :cols="profileData.Phones.length > 1 ? 11 : 12" class="ma-0 pa-0">
                            <v-row no-gutters>
                                <v-col cols="12" lg="3" class="ma-0 pa-0">
                                    <HbSelect v-model="phone.type"
                                        v-validate="'required'" :items="phone_types"
                                        placeholder="Type" data-vv-scope="general" data-vv-name="type"
                                        data-vv-as="Phone Type" :error="errors.collect('general.type').length > 0"
                                        :clearable="false" />
                                </v-col>
                                <v-col cols="12" lg="3" class="ma-0 pa-0"
                                    :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                                    <hb-autocomplete v-model="phone.code"
                                        v-validate="'required'"
                                        :items="countryCodesList" placeholder="Code" :id="'phone_code_' + i"
                                        :name="'phone_code_' + i" data-vv-scope="general" data-vv-name="code"
                                        data-vv-as="Phone Code" :error="errors.collect('general.phone_code').length > 0"
                                        @change="checkValidPhone(phone.code, phone.number, i, 'default')"
                                        @focusout="checkValidPhone(phone.code, phone.number, i, 'default')"
                                        :clearable="false">
                                        <template v-slot:prepend-inner>
                                            +
                                        </template>
                                        <template v-slot:item="data">
                                            +{{ data.item }}
                                        </template>
                                    </hb-autocomplete>
                                </v-col>
                                <v-col cols="12" lg="4" class="ma-0 pa-0"
                                    :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                                    <HbTextField v-model="phone.number" v-validate="'max:16|required'"
                                        v-mask="phoneMask(phone.code)" placeholder="Phone Number"
                                        :id="'contact-phone-' + i" :name="'contact-phone-' + i" data-vv-scope="general"
                                        :data-vv-name="'phone' + i" data-vv-as="Phone Number"
                                        :autocomplete="'cc-contact-phone-' + i"
                                        :error="(errors.collect('general.phone' + i).length > 0 || invalidPhones.indexOf('default' + i) >= 0)"
                                        @change="checkValidPhone(phone.code, phone.number, i, 'default')"
                                        @focusout="checkValidPhone(phone.code, phone.number, i, 'default')" />
                                </v-col>
                                <v-col cols="12" lg="2"
                                    :class="{ 'pl-2': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                                    <HbCheckbox v-model="phone.sms" label="SMS" />
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-spacer v-if="profileData.Phones.length > 1"></v-spacer>

                        <v-col cols="1" v-if="profileData.Phones.length > 1" class="ma-0 pa-0" align="right">
                            <hb-icon @click="removePhone('contact', i)" class="hand">
                                mdi-close
                            </hb-icon>
                        </v-col>
                    </div>
                    <div v-if="i === profileData.Phones.length - 1" class="mt-4 mb-1">
                        <hb-link @click="addPhone()">+ Add Phone Number</hb-link>
                    </div>
                </hb-form>

                <hb-form :label="isBusinessProfile ? 'Name' : 'Business Name'" required full>
                    <v-row no-gutters>
                        <v-col cols="12" :sm="isBusinessProfile ? '6' : '12'" class="ma-0 pa-0">
                            <HbTextField v-model.lazy="profileData.first" v-validate="'required|max:45'"
                                :placeholder="isBusinessProfile ? 'First' : 'Business Name'" id="first" name="first"
                                data-vv-scope="general" :data-vv-as="'First Name'"
                                :error="errors.collect('general.first').length > 0" autocomplete="cc-contact-first-name"
                                :full="$vuetify.breakpoint.smAndUp" />
                        </v-col>
                        <v-col cols="12" :sm="isBusinessProfile ? '6' : '12'" class="ma-0 pa-0"
                            :class="{ 'pl-3': $vuetify.breakpoint.smAndUp, 'mt-3': $vuetify.breakpoint.xs }">
                            <HbTextField v-model.lazy="profileData.last" v-validate="'required|max:45'" placeholder="Last"
                                id="last" name="last" data-vv-scope="general" data-vv-as="Last Name"
                                :error="errors.collect('general.last').length > 0" autocomplete="cc-contact-last-name"
                                :full="$vuetify.breakpoint.smAndUp" v-if="isBusinessProfile" />
                        </v-col>
                    </v-row>
                </hb-form>

                <hb-form v-for="(address, i) in profileData.Addresses" :key="'contact_address' + i"
                    :label="profileData.Addresses.length > 1 ? 'Address ' + (i + 1) : 'Address'" required full>
                    <div class="d-flex align-top">
                        <v-col :cols="profileData.Addresses.length > 1 ? 11 : 12" class="ma-0 pa-0">
                            <HbAutocomplete :items="countriesListMixin" v-validate="'required'"
                                v-model="profileData.Addresses[i].Address.country" label="Country"
                                :id="'address_country_' + i" data-vv-scope="general" data-vv-as="country"
                                :data-vv-name="'country' + i" :name="'country' + i"
                                :error="errors.collect('general.country' + i).length > 0" @change="setCountry(i)">
                                <template v-slot:selection="data">
                                    {{ data.item }}
                                </template>
                                <template v-slot:item="data">
                                    {{ data.item }}
                                </template>
                            </HbAutocomplete>

                            <HbTextField v-model="profileData.Addresses[i].Address.address" v-validate="'required|max:45'"
                                placeholder="Street" :id="'address' + i" :name="'address' + i" data-vv-scope="general"
                                :data-vv-name="'address' + i" data-vv-as="Street"
                                :error="errors.collect('general.address' + i).length > 0"
                                :autocomplete="'cc-contact-address-' + i" />

                            <HbTextField v-model="profileData.Addresses[i].Address.address2" v-validate="'max:45'"
                                placeholder="Suite / Apt" :id="'address2' + i" :name="'address2' + i"
                                data-vv-scope="general" :data-vv-name="'address2' + i" data-vv-as="Suite / Apt"
                                :error="errors.collect('general.address2' + i).length > 0"
                                :autocomplete="'cc-contact-address2-' + i" />

                            <v-row no-gutters>

                                <v-col cols="12" lg="3" class="ma-0 pa-0">
                                    <HbTextField v-model="profileData.Addresses[i].Address.zip" v-validate="'required'"
                                        placeholder="ZIP Code" :id="'zip' + i" :name="'zip' + i" data-vv-scope="general"
                                        :data-vv-name="'zip' + i" data-vv-as="ZIP Code"
                                        :error="errors.collect('general.zip' + i).length > 0"
                                        :autocomplete="'cc-contact-zip-' + i" />
                                </v-col>
                                <v-col cols="12" lg="4" class="ma-0 pa-0"
                                    :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                                    <HbAutocomplete :items="stateListMixin[i] || []"
                                        v-validate="stateListMixin[i] && stateListMixin[i].length ? 'required' : ''"
                                        v-model="profileData.Addresses[i].Address.state" item-text='name'
                                        item-value='value' label="State" single-line :id="'address_state_' + i"
                                        data-vv-scope="general" data-vv-as="state" :data-vv-name="'state' + i"
                                        :name="'state' + i" :error="errors.collect('general.state' + i).length > 0"
                                        @change="setState(i)">
                                    </HbAutocomplete>
                                </v-col>
                                <v-col cols="12" lg="4" class="ma-0 pa-0"
                                    :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                                    <HbAutocomplete :items="cityListMixin[i] || []"
                                        v-validate="cityListMixin[i] && cityListMixin[i].length ? 'required' : ''"
                                        v-model="profileData.Addresses[i].Address.city" label="City" single-line
                                        :id="'address_city_' + i" data-vv-scope="general" data-vv-as="city"
                                        :data-vv-name="'city' + i" :name="'city' + i"
                                        :error="errors.collect('general.city' + i).length > 0">
                                        <template v-slot:selection="data">
                                            {{ data.item }}
                                        </template>
                                        <template v-slot:item="data">
                                            {{ data.item }}
                                        </template>
                                    </HbAutocomplete>
                                </v-col>

                            </v-row>

                        </v-col>

                    </div>
                </hb-form>

                <hb-form label="Gate Code" v-if="profileData.access.length > 0">
                    <div>{{ profileData.access[0].pin }}</div>
                </hb-form>

                <hb-form label="Taxpayer ID" v-if="!isBusinessProfile">
                    <template v-slot:tooltipBody>
                                <div style="width: 250px">
                                    Taxpayer ID is a 9 digits identifying number used for tax purposes in the United States and in other countries under the Common Reporting Standard.
                                </div>
                    </template>  
                    <v-row no-gutters>
                        <v-col cols="12" lg="6" class="ma-0 pa-0">  
                            <HbTextField v-model.lazy="profileData.taxpayer_number" placeholder="Taxpayer ID"
                                id="taxpayer_number" v-validate="'numeric|min:9|max:9'" v-mask="'#########'" name="taxpayer_number" data-vv-scope="general"
                                data-vv-name="taxpayer_number" data-vv-as="Tax Payer Number"
                                :error="errors.collect('general.taxpayer_number').length > 0" />
                        </v-col>
                    </v-row>
                </hb-form>
                <hb-form label="Business Representative Information" required full v-if="!isBusinessProfile">
                    <HbTextField v-model.lazy="profileData.Representative.Contact.first" v-validate="'required|max:45'"
                        placeholder="First Name" :id="'representative_first'" :name="'representative_first'"
                        data-vv-scope="general" :data-vv-name="'representative_first'"
                        :data-vv-as="'Representative First Name'"
                        :error="errors.collect('general.representative_first').length > 0" />

                    <HbTextField v-model.lazy="profileData.Representative.Contact.last" v-validate="'required|max:45'"
                        placeholder="Last Name" :id="'representative_last'" :name="'representative_last'"
                        data-vv-scope="general" :data-vv-name="'representative_last'"
                        :data-vv-as="'Representative Last Name'"
                        :error="errors.collect('general.representative_last').length > 0" />

                    <HbTextField v-model="profileData.Representative.Contact.email" v-validate="{ required: true, max:45, regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ }"
                        placeholder="Email" :id="'representative_email'" :name="'representative_email'"
                        data-vv-scope="general" :data-vv-name="'representative_email'" data-vv-as="Representative Email"
                        :error="errors.collect('general.representative_email').length > 0" />
                    <v-row v-for="(phones, j) in profileData.Representative.Contact.Phones"
                        :key="'representative_phone' + j" no-gutters>
                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                            <HbSelect v-model="profileData.Representative.Contact.Phones[j].type" v-validate="'required'"
                                :items="phone_types" placeholder="Type" :name="'representative__phone_type'"
                                data-vv-scope="general" :data-vv-name="'representative__phone_type'"
                                data-vv-as="Representative Phone Type"
                                :error="errors.collect('general.representative__phone_type').length > 0"
                                :clearable="false" />
                        </v-col>
                        <v-col cols="12" lg="3" class="ma-0 pa-0"
                            :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                            <hb-autocomplete v-model="profileData.Representative.Contact.Phones[j].code"
                                v-validate="'required'" :items="countryCodesList" placeholder="Code"
                                :id="'representative_phone_code'" :name="'representative_phone_code'"
                                data-vv-scope="general" :data-vv-name="'representative_phone_code'"
                                data-vv-as="Representative Phone Code"
                                :error="errors.collect('general.representative_phone_code').length > 0"
                                @change="checkValidPhone(profileData.Representative.Contact.Phones[j].code, profileData.Representative.Contact.Phones[j].number, j, 'representative')"
                                @focusout="checkValidPhone(profileData.Representative.Contact.Phones[j].code, profileData.Representative.Contact.Phones[j].number, j, 'representative')"
                                :clearable="false">
                                <template v-slot:prepend-inner>
                                    +
                                </template>
                                <template v-slot:item="data">
                                    +{{ data.item }}
                                </template>
                            </hb-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="4" class="ma-0 pa-0"
                            :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                            <HbTextField v-model="profileData.Representative.Contact.Phones[j].number"
                                v-validate="'required|max:16'"
                                v-mask="phoneMask(profileData.Representative.Contact.Phones[j].code)"
                                placeholder="Phone Number" data-vv-scope="general"
                                :data-vv-name="'representative_phone_number' + j" data-vv-as="Representative Phone Number"
                                :error="(errors.collect('general.representative_phone_number' + j).length > 0 || invalidPhones.indexOf('representative' + j) >= 0)"
                                @change="checkValidPhone(profileData.Representative.Contact.Phones[j].code, profileData.Representative.Contact.Phones[j].number, j, 'representative')"
                                @focusout="checkValidPhone(profileData.Representative.Contact.Phones[j].code, profileData.Representative.Contact.Phones[j].number, j, 'representative')" />
                        </v-col>
                        <v-col cols="12" lg="2"
                            :class="{ 'pl-2': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                            <HbCheckbox v-model="profileData.Representative.Contact.Phones[j].sms" label="SMS" />
                        </v-col>
                    </v-row>
                </hb-form>

                <hb-form label="Driver's License" required full>
                    <v-row no-gutters>

                        <v-col cols="12" lg="6" class="ma-0 pa-0">
                            <HbTextField v-model.lazy="profileData.driver_license" v-validate="'required|max:45'"
                                placeholder="License Number" id="driver_license" name="driver_license"
                                data-vv-scope="general" data-vv-name="driver_license" data-vv-as="Driver's License Number"
                                :error="errors.collect('general.driver_license').length > 0" />
                        </v-col>
                        <v-col cols="12" lg="6" class="ma-0 pa-0"
                            :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                            <div style="margin-top: -23px;">
                                <HbDatePicker v-model.lazy="profileData.driver_license_exp" v-validate="'required'"
                                    dateFormat='MM-DD-YYYY' mask="##-##-####" label="Expiration Date (MM-DD-YYYY)"
                                    :clearable="true" clear-icon="mdi-close-circle" :solo="false" :single-line="true"
                                    :canWrite="true" :min="currentDate" data-vv-scope="general"
                                    data-vv-name="driver_license_expiry_date" data-vv-as="Driver's License Expiration Date"
                                    :error="errors.collect('general.driver_license_expiry_date').length > 0" />
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" class="ma-0 pa-0 mt-3" :class="{ 'mt-3': $vuetify.breakpoint.mdAndDown }">
                            <HbAutocomplete :items="countriesListMixin" v-validate="'required'"
                                v-model="profileData.driver_license_country" label="Country" id="driver_license_country"
                                data-vv-scope="general" data-vv-as="Driver's License Country"
                                data-vv-name="driver_license_country" name="driver_license_country"
                                :error="errors.collect('general.driver_license_country').length > 0" @change="setDLCountry">
                                <template v-slot:selection="data">
                                    {{ data.item }}
                                </template>
                                <template v-slot:item="data">
                                    {{ data.item }}
                                </template>
                            </HbAutocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" class="ma-0 pa-0 mt-3"
                            :class="{ 'pl-3': $vuetify.breakpoint.lgAndUp, 'mt-3': $vuetify.breakpoint.mdAndDown }">
                            <HbAutocomplete :items="stateDLListMixin" v-validate="stateDLListMixin.length ? 'required' : ''"
                                v-model="profileData.driver_license_state" label="State" item-text="name"
                                item-value="value" id="driver_license_state" name="driver_license_state"
                                data-vv-scope="general" data-vv-name="driver_license_state"
                                data-vv-as="Driver's License State"
                                :error="errors.collect('general.driver_license_state').length > 0">
                            </HbAutocomplete>
                        </v-col>
                    </v-row>
                </hb-form>

            </template>

            <template v-slot:right-actions>
                <hb-btn @click="validateAndShowConversionConfirmationModel" color="primary">Convert</hb-btn>
            </template>
        </hb-modal>

        <hb-modal size="medium" :title="isBusinessProfile ? TENANT.TENANT_PROFILE.TITLE : TENANT.BUSINESS_PROFILE.TITLE"
            v-model="showConversionConfirmationPopup" confirmation @close="showConversionConfirmationPopup = false">
            <template v-slot:content>
                <div class="py-4 px-6">
                    {{ isBusinessProfile ? TENANT.TENANT_PROFILE.CONFIRMATION_TEXT :
                        TENANT.BUSINESS_PROFILE.CONFIRMATION_TEXT }}
                    <br /><br />
                    {{ isBusinessProfile ? TENANT.BUSINESS_PROFILE.CONFIRMATION_DESC : '' }}
                </div>
            </template>
            <template v-slot:right-actions>
                <hb-btn :loading="loader" :disabled="loader" @click="convertProfile()">Convert</hb-btn>
            </template>
        </hb-modal>

    </div>
</template>
<script>
import { notificationMixin } from '@/mixins/notificationMixin.js';
import { validationMixin } from '@/mixins/validationMixin.js';
import { countryStateCityMixin } from '@/mixins/countryStateCityMixin';
import { validateEmailPhoneMixin } from '@/mixins/validateEmailPhoneMixin'
import { EventBus } from '../../EventBus.js';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Validator } from 'vee-validate';
import HbDatePicker from '../assets/HummingbirdDatepicker';
import PHONE from "@/constants/phone.js";
import TENANT from "@/constants/tenant.js";
import moment from 'moment';
import api from '../../assets/api.js';

Validator.extend('leadingZeros', {
    getMessage: field => "Leading 0's are not allowed for " + field + " field.",
    validate: (value) => {
        let regex = /^[1-9]\d*$/;
        return regex.test(value)
    }
});

export default {
    name: "ConvertTenantProfileModel",
    props: ["value", "contact"],
    mixins: [notificationMixin, validationMixin, validateEmailPhoneMixin, countryStateCityMixin],
    components: {
        HbDatePicker
    },
    data() {
        return {
            showConversionConfirmationPopup: false,
            phone_types: PHONE.TYPES,
            countryCodesList: PHONE.COUNTRY_CODES,
            defaultPhoneType: 'Cell',
            invalidPhones: [],
            defaultCountry: 'United States',
            currentDate: '',
            form: {},
            loader: false,
            profileData: {
                id: null,
                first: '',
                last: '',
                email: '',
                taxpayer_number:'',
                access:[{
                    pin:''
                }],
                Phones: [
                        {
                            id: null,
                            code:"1",
                            number:'',
                            phone: 'Cell',
                            type: '',
                            sms: false
                        }
                ],
                Addresses:[{
                        type:'',
                        Address:{
                            address: '',
                        address2: '',
                        country: this.defaultCountry,
                        city: '',
                        state: '',
                        zip: '',
                        },
                }],
                driver_license: '',
                driver_license_exp: '',
                driver_license_country: '',
                driver_license_state: '',
                Representative:{
                    id: null,
                    type: '',
                    is_emergency: false,
                    is_cosigner: false,
                    is_military: false,
                    is_authorized: false,
                    is_lien_holder: false,
                    is_business_representative: 1,
                    lease_id: '',
                    Contact: {
                        id: null,
                        first: '',
                        last: '',
                        email: '',
                        Phones: [
                            {
                                id: null,
                                code:"1",
                                number:'',
                                phone: 'Cell',
                                type: '',
                                sms: false
                            }
                        ],
                        Addresses:[{
                            type:'',
                            Address:{
                                address: '',
                            address2: '',
                            country: this.defaultCountry,
                            city: '',
                            state: '',
                            zip: '',
                            },
                        }]
                    }
                }
            }
        }
    },
    async created() {
        this.TENANT = TENANT;
        this.currentDate = moment().format('YYYY-MM-DD');
        this.profileData = JSON.parse(JSON.stringify(this.contact));
        await this.setDefaultPhoneType();
        await this.transformPhoneObject();
        await this.transformRepresentative();
        await this.setCountiesAndStates();
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        isBusinessProfile() {
            return this.contact.rent_as_business ? true : false;
        },
    },
    methods: {
        async convertProfile() {
            this.loader = true;
            let contact = {
                    id: this.profileData.id,
                    company_id: this.profileData.company_id, 
                    first: this.profileData.first,
                    last: this.isBusinessProfile ? this.profileData.last : '',   
                    email: this.profileData.email,
                    Phones: this.profileData.Phones,
                    Addresses: this.profileData.Addresses,
                    Relationships: this.profileData.Relationships, 
                    driver_license: this.profileData.driver_license,
                    driver_license_country:  this.profileData.driver_license_country,
                    driver_license_state:  this.profileData.driver_license_state,
                    driver_license_city: this.profileData.driver_license_city,
                    driver_license_exp: moment(this.profileData.driver_license_exp).format('YYYY-MM-DD') == 'Invalid date' ? '' : moment(this.profileData.driver_license_exp).format('YYYY-MM-DD'),
                    contact_id: this.profileData.id,
                    taxpayer_number: !this.isBusinessProfile ? this.profileData.taxpayer_number : '',
                    rent_as_business: this.isBusinessProfile ? false : true,
            }
            if (!this.isBusinessProfile) {
                contact.scope = "contacts";
                this.profileData.Representative.is_business_representative = 1;
                this.profileData.Representative.is_move_in = 1;
                contact.Relationships.push(this.profileData.Representative);
            }
            try {
                let r = await api.put(this.$app, api.CONTACTS + "convert-contact", contact);
                let profile = this.isBusinessProfile ? 'Individual Profile.' : 'Business Profile.'
                EventBus.$emit('reload_tenant_profile_leases');
                setTimeout(() => {
                    this.showMessageNotification({
                        type: "success",
                        description: "Profile has successfully been converted to " + profile,
                    });
                    this.showConversionConfirmationPopup = false;
                    if(this.isBusinessProfile && this.phone_types.indexOf('Office Phone') > -1){
                        this.phone_types.shift('Office Phone');
                    }
                    this.loader = false;
                    this.$emit('close'); 
                }, 2500);
            } catch (error) {
                this.loader = false;
                this.showMessageNotification({
                    type: "error",
                    description: error,
                });
            }
        },
        async validateAndShowConversionConfirmationModel() {
            const isValid = await this.$validator.validateAll();
            if (!isValid) {
                this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                return;
            }
            let general_status = await this.$validator.validateAll('general');

            if (!general_status) {
                this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                return;
            }

            if (this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') === -1) {
                this.errors.items.push('Please enter valid phone number(s) before continuing.');
            }

            if (!this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') >= 0) {
                var invalidPhoneIndex = this.errors.items.indexOf('Please enter valid phone number(s) before continuing.');
                this.errors.items.splice(invalidPhoneIndex);
            }

            if (this.invalidPhones.length) {
                this.showMessageNotification({ list: ['Please enter valid phone number(s) before continuing.'] });
                return;
            }
            this.showConversionConfirmationPopup = true;
        },
        validateEmailPhone() {
            // Purpose of this method is to check if either Email or Phone is added

            // This condition is to check if both are not added and returns true otherwise false
            if (!this.profileData.email && !this.profileData.Phones[0].number) {
                return true
            }
            return false
        },
        addPhone() {
            this.profileData.Phones.push({
                type: this.defaultPhoneType, // 'Cell',
                code: '1',
                number: '',
                phone: '',
                sms: false
            });
        },
        removePhone(type, i) {
            if (type == 'contact' && this.profileData.Phones.length > 1) {
                this.profileData.Phones.splice(i, 1);
            }
        },
        phoneMask(code) {
            switch (parseInt(code)) {
                case 1:
                    return "(###) ###-####";
                default:
                    return "(###) ###-####";
            }
        },
        checkValidPhone(code, number, i, type) {
            let r = false;

            if (code || number) {
                var parsedPhoneNumber;
                try {
                    parsedPhoneNumber = parsePhoneNumber('+' + code + number);
                } catch (err) {
                }
                if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
                    switch (type) {
                        case 'representative':
                            this.profileData.Representative.Contact.Phones[i].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                            if (this.invalidPhones.indexOf(type + i) != -1) {
                                this.invalidPhones.splice(this.invalidPhones.indexOf(type + i), 1);
                            }
                            break;
                        default:
                            this.profileData.Phones[i].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                            if (this.invalidPhones.indexOf(type + i) != -1) {
                                this.invalidPhones.splice(this.invalidPhones.indexOf(type + i), 1);
                            }
                            break;
                    }
                    r = true;
                } else if ((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length > 0) {
                    if (this.invalidPhones.indexOf(type + i) != -1) {
                        '';
                    } else {
                        this.invalidPhones.push(type + i);
                    }
                    //return false;
                    r = false;
                } else {
                    if (this.invalidPhones.indexOf(type + i) != -1) {
                        this.invalidPhones.splice(this.invalidPhones.indexOf(type + i), 1);
                    }
                    //return false;
                    r = true;
                }

            }
            return r;
        },
        transformPhoneObject() {
            if (this.profileData.Phones.length < 1) {
                this.profileData.Phones.push(
                    {
                        id: null,
                        type: this.defaultPhoneType,
                        code: '1',
                        number: '',
                        phone: '',
                        sms: false
                    }
                )
            }
            this.profileData.Phones = this.profileData.Phones.map(p => {
                try {
                    var parseEditedPhone = parsePhoneNumber('+' + p.phone);
                } catch (err) {
                }

                if (!parseEditedPhone) {
                    return {
                        id: p.id,
                        sms: p.sms,
                        phone: p.phone,
                        code: '1',
                        number: p.phone,
                        type: this.defaultPhoneType
                    }
                } else {
                    return {
                        id: p.id,
                        sms: p.sms,
                        phone: p.phone,
                        code: parseEditedPhone.countryCallingCode,
                        number: parseEditedPhone.nationalNumber,
                        type: this.defaultPhoneType
                    }
                }

            });
        },
        transformRepresentative() {
            if (!Object.keys(this.profileData.Representative).length) {
                this.profileData.Representative = {
                    id: null,
                    type: '',
                    is_emergency: false,
                    is_cosigner: false,
                    is_military: false,
                    is_authorized: false,
                    is_lien_holder: false,
                    is_business_representative: 1,
                    lease_id: '',
                    Contact: {
                        id: null,
                        first: '',
                        last: '',
                        email: '',
                        Phones: [{
                            id: null,
                            type: this.defaultPhoneType,
                            code: '1',
                            number: '',
                            phone: '',
                            sms: false
                        }],
                        Addresses: [
                            {
                                id: null,
                                type: 'Home',
                                address_id: '',
                                Address: {
                                    address: '',
                                    address2: '',
                                    city: '',
                                    neighborhood: '',
                                    lat: '',
                                    lng: '',
                                    state: '',
                                    zip: ''
                                }
                            }
                        ],
                    }
                };
            }
            this.profileData.Representative.Contact.Phones.map(p => {
                try {
                    var parseEditedPhone = parsePhoneNumber('+' + p.phone);
                } catch (err) {
                }

                if (!parseEditedPhone) {
                    return {
                        id: p.id,
                        sms: p.sms,
                        phone: p.phone,
                        code: '1',
                        number: p.phone,
                        type: this.defaultPhoneType
                    }
                } else {
                    return {
                        id: p.id,
                        sms: p.sms,
                        phone: p.phone,
                        code: parseEditedPhone.countryCallingCode,
                        number: parseEditedPhone.nationalNumber,
                        type: this.defaultPhoneType
                    }
                }
            });
        },
        async setDefaultPhoneType() {
            if (this.isBusinessProfile) {
                this.defaultPhoneType = 'Cell';
                if(this.phone_types.indexOf('Office Phone') > -1){
                    this.phone_types.shift('Office Phone');
                }
            } else {
                this.phone_types.unshift('Office Phone');
                this.defaultPhoneType = 'Office Phone';
            }
        },
        setCountiesAndStates() {
            this.setCountriesListEvent();
            this.profileData.Addresses.map((value, i) => {
                if (!value.Address.country) {
                    this.profileData.Addresses[i].Address.country = this.defaultCountry;
                }
                this.setStatesListEvent(value.Address.country || this.defaultCountry, i);
                if (value.Address.state) {
                    this.setCitiesListEvent(this.profileData.Addresses[i].Address.country, value.Address.state, i);
                }
            });
            this.setDLStatesListEvent(this.profileData.driver_license_country);
        },
        setCountry(index) {
            this.profileData.Addresses[index].Address.state = '';
            this.profileData.Addresses[index].Address.city = '';
            this.profileData.Addresses[index].Address.country && this.setStatesListEvent(this.profileData.Addresses[index].Address.country, index);
        },
        setState(index) {
            this.profileData.Addresses[index].Address.city = '';
            this.profileData.Addresses[index].Address.country && this.profileData.Addresses[index].Address.state && this.setCitiesListEvent(this.profileData.Addresses[index].Address.country, this.profileData.Addresses[index].Address.state, index);
        },
        setDLCountry() {
            this.profileData.driver_license_state = '';
            this.profileData.driver_license_country && this.setDLStatesListEvent(this.profileData.driver_license_country);
        },
        setRelationCountry(index) {
            this.profileData.Relationships[index].Contact.Addresses[0].Address.state = '';
            this.profileData.Relationships[index].Contact.Addresses[0].Address.city = '';
            this.profileData.Relationships[index].Contact.Addresses[0].Address.country && this.setRelationStatesListEvent(this.profileData.Relationships[index].Contact.Addresses[0].Address.country, index);
        },
        setRelationState(index) {
            this.profileData.Relationships[index].Contact.Addresses[0].Address.city = '';
            this.profileData.Relationships[index].Contact.Addresses[0].Address.country && his.profileData.Relationships[index].Contact.Addresses[0].Address.state && this.setRelationCitiesListEvent(this.profileData.Relationships[index].Contact.Addresses[0].Address.country, this.profileData.Relationships[index].Contact.Addresses[0].Address.state, index)
        },
    }
}
</script>