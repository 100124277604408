<template>
  <div class="unlink-form">
    <v-row
      v-if="loading"
      style="height: 500px;"
      align="center"
      justify="center"
      no-gutters
    >
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-row>
    <hb-form label="Access Code" :description="getFormDescription" required v-else>
      <div>
        <HbTextField
          v-model="pin"
          v-validate="'required|min:4|max:4'"
          :rules="pinRules"
          id="pin"
          name="pin"
          data-vv-as="code"
          :error="errors.has('pin')"
          placeholder="Enter Access Code"
          v-if="computedIsSpaceAccessCodesVendor"
        />
        <HbTextField
          v-model="pin"
          v-validate="validationRule"
          :rules="pinRules"
          id="pin"
          name="pin"
          data-vv-as="code"
          :error="errors.has('pin')"
          placeholder="Enter Access Code"
          v-else
        />
      </div>      
    </hb-form>
  </div>
</template>

<script type="text/babel">
import { notificationMixin } from '@/mixins/notificationMixin.js';
import api from '../../../assets/api';
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "GateAccess",
  props: ["lease", "contact", "property_id"],
  mixins:[notificationMixin],
  data() {
    return {
      pin: '',
      pin_format: '8',
      default_pin_format: '8',
      pin_min: null,
      pin_max: null,
      default_pin_min: '3',
      default_pin_max: '12',
      modalIsSpaceAccessCodesVendor: false,
      loading: false,
      selected: {
        pin: '',
      },
    };
  },
  computed: {
    validationRule(){
      return 'required|numeric|min:' + this.pin_min + '|max:' + this.pin_max;
    },
    pinRules: function(){
      if(this.computedIsSpaceAccessCodesVendor){
        return [
          v => v.length === 4
        ]
      } else {
        return [
          v => v.length >= this.pin_min,
          v => v.length <= this.pin_max
        ]
      }
    },
    getFormDescription(){
      return this.lease ? `Space ${this.lease.Unit.number} | ${this.lease.Unit.Property.number} - ${this.lease.Unit.Property.Address.city} - ${this.lease.Unit.Property.Address.address}` : ''
    },
    computedIsSpaceAccessCodesVendor(){
      if(this.modalIsSpaceAccessCodesVendor){
        return true;
      } else {
        return false;
      }
    }
  },
  async created(){
    this.loading = true;
    this.contact_id = this.contact.id;

    if (this.pinCode?.length > 0) this.pin = this.pinCode;

    if(this.property_id){
      await this.modalCheckIsSpaceAccessCodesVendor(this.property_id);
    }

    if(this.computedIsSpaceAccessCodesVendor){
      await this.fetchSpaceAccessCodesData();
    } else {
      await this.fetchData();
    }
    this.loading = false;
  },
  methods: {
    ...mapMutations({
      setPin: "contactStore/setGateAccessPin",
      setIsSpaceAccessCodesVendor: "contactStore/setIsSpaceAccessCodesVendor"
    }),
    async fetchData(){
      await api.get(this, api.CONTACTS + this.contact_id + '/access').then(r => {
        this.access = r.access;
        this.selected = this.access.find(a => a.property_id = this.property_id);

        this.pin_format = this.selected.Access.pin_format || this.default_pin_format;
        this.pin_min = this.selected.Access.pin_min || this.default_pin_min;
        this.pin_max = this.selected.Access.pin_max || this.default_pin_max;
      }).catch(err => {
        this.showMessageNotification({ description: err });
      });
    },
    async modalCheckIsSpaceAccessCodesVendor(property_id){
      if(property_id){
        try {
          let r = await api.get(this, api.PROPERTIES + property_id + '/access');

          if(r.property_access_name === 'Derrels'){
            this.modalIsSpaceAccessCodesVendor = true;
            this.setIsSpaceAccessCodesVendor(true);
          }
        } catch (err){
          this.showMessageNotification({ description: err });
        }
      }
    },
    async fetchSpaceAccessCodesData(){
      await api.get(this, api.CONTACTS + this.contact_id + '/space-access').then(r => {
        this.spaceAccess = r.access;
        this.selected = this.spaceAccess.find(a => a.property_id = this.property_id);

      }).catch(err => {
        this.showMessageNotification({ description: err });
      });
    },
  },
  watch: {
    pin(newValue, oldValue) {
      this.setPin(newValue);
      this.$emit('onChange', 2);
    }
  },
};

</script>
<style lang="scss">
.unlink-form .hb-aviary-form-padding-content .hb-form-description-text-night-light {
  font-size: 12px !important;
}
</style>