<template>
    <div>
        <div class="omni-search-wrapper" style="width:580px;">
          <p class="text-heading"><hb-icon mdi-code="mdi-link-variant" color="#101318"></hb-icon> Search for tenant</p>
          <div class="omni-search-container" @click="$event.stopPropagation()">
            <div class="omni-search-field" @click.stop="$refs.searchfield.focus()">
              <HbTextField
                ref="searchfield"
                placeholder="Search for a tenant"
                @input="search"
                v-model="params.search"
                maxlength="50"
                class="searchbox"
                max-width="500"
                :error="inputSearchError"
                hide-details
                autofocus
                clearable
              >
                  <template v-slot:prepend-inner>
                      <v-icon color="#757575">mdi-magnify</v-icon>
                  </template>
              </HbTextField>
            </div>
          </div>
        </div>
        <v-row class="ma-0">
          <v-col class="pa-0" cols="12">
              <v-row class="ma-0">
                <v-col cols="12" class="pa-0 elevation-2 search-result-list">
                  <div class="results-container" @scroll.self="searchNextTabResults">
                    <span>
                      <div class="omni-search-result-item">
                          <v-list-item v-for="(r,index) in results" :key="'contact' + index" class="px-6 result-item">
                            <span class="result-item-border py-4">
                              <v-list-item-content style="align-self:center;" class="py-0" @click="viewLease(r)">
                                <mini-profile-view 
                                    v-if="r?.id"
                                    :contact-id="r.id"
                                    :use-contact="true"
                                    :contact-data="r"
                                />
                              </v-list-item-content>
                            </span>
                          </v-list-item>
                          <v-list-item v-show="!isFinished|| !isTopResultsLoading" >
                              <v-list-item-content >
                                  <v-list-item-title class="text-left ml-2 font-weight-medium">
                                  <v-progress-circular
                                      :size="20"
                                      indeterminate
                                      color="primary"
                                      ></v-progress-circular>
                                      Loading...
                                  </v-list-item-title>
                                  <!-- <img src="../../assets/images/global-search-watermark.svg" height="294" class="pt-12" alt=""> -->
                              </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="isFinished && !results.length && isTopResultsLoading && this.params.search && this.params.search.length > 0">
                              <v-list-item-content>
                                  <v-list-item-title>
                                      <span class="omni-search-underline-text">No contacts found</span>
                                  </v-list-item-title>
                                  <!-- <img src="../../assets/images/global-search-watermark.svg" height="286" class="pt-10" alt=""> -->
                              </v-list-item-content>
                          </v-list-item>
                      </div>
                    </span>
  
                  </div>
                </v-col>
              </v-row>
          </v-col>
      </v-row>
    </div>
  
  </template>
  
  <script type="text/babel">
      import Status from '../../includes/Messages.vue';
      import api from '../../../assets/api.js';
      import UnitView from '../../units/UnitView.vue';
      import { mapGetters, mapMutations } from 'vuex';
      import MiniProfileView from '../../includes/LeadIntake/MiniProfileView.vue';
  
      export default {
          name: "LinkContactOmniSearch",
          data() {
              return {
                  params:{
                      search: '',
                      limit: 10,
                      offset: 0
                  },
                  category: 'contacts',
                  results: [],
                  isFinished: true,
                  selected: null,
                  showWindow: false,
                  result_time: '',
                  unit_time: '',
                  contact_time: '',
                  resultParams: {
                      search: '',
                      limit: 20,
                      offset: 0,
                      result_count: 0
                  },
                  defaultOffset: 20,
                  isTopResultsLoading: true,
                  isSearchResults: true,
              }
          },
          components:{
              Status,
              UnitView,
              MiniProfileView
          },
          created(){
              this.$emit('changeText', this.params.search)
          },
          computed:{
              ...mapGetters({
                  properties: 'propertiesStore/filtered'
              }),
          },
          mounted(){
              this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
              if(this.size) this.modalSize = 'modal-' + this.size;
              if(this.blank) this.modalBlank = 'modal-blank';
              window.scrollTo(0, this.scrollTop);
              document.body.style.top =  '-' + this.scrollTop + 'px';
              setTimeout(() => {
                  this.$refs.searchfield.focus();
              }, 100);
          },
          destroyed(){
              document.body.classList.remove('noscroll');
              document.body.scrollTop = this.scrollTop;
              this.selected = null;
              this.showWindow = false;
              this.selected = null;
          },
          props:['size', 'blank', 'hideHeader', 'inputSearchError', 'contact', "currentProperty"],
          methods: {
              ...mapMutations({
                  setSecondContact: 'contactStore/setSecondContact',
                  setSecondaryContactId: 'contactStore/setSecondaryContactId',
              }),
              searchNextTabResults({ target: { scrollTop, clientHeight, scrollHeight }}) {
                this.searchNextResults({ target: { scrollTop, clientHeight, scrollHeight }});
              },
              async searchNextResults({ target: { scrollTop, clientHeight, scrollHeight }}){
                  if (scrollTop + clientHeight >= scrollHeight) {
                      if (this.isTopResultsLoading) {
                          this.isTopResultsLoading = false
                          this.isSearchResults = true;
                          await this.searchResults()
                          this.isSearchResults = false;
                          this.isTopResultsLoading = true
                      }
  
                  }
              },
  
              search:  _.debounce(async function() {
  
                  this.$emit('changeText', this.params.search)
                  // Cache params
                  this.isFinished = false;
                  this.results = [];
                  this.isSearchResults = true;
                  this.resultParams = {
                      search: this.params.search,
                      limit: 20,
                      offset: 0,
                      result_count: 0
                  }
                    await this.searchResults();
              }, 300),
  
              async searchResults () {
                var d = new Date();
                this.result_time = d.getTime();
                
                if (this.results.length > 0) {
                    
                  this.resultParams.offset += this.defaultOffset
                }
                let data = JSON.parse(JSON.stringify(this.resultParams));
                data.t = this.result_time;
                if (this.results.length % data.limit === 0 && this.isSearchResults) {
                  this.isFinished = false;
                  data.contact_id = this.contact.id;
                  data.source = 'linking';
                  data.property_id = this.currentProperty;
                  let r = await api.post(this, api.UNITS_OMNI + '?type=contact', data)
                  this.isSearchResults = false;
                  this.resultParams.result_count = r.count
                  if (this.result_time === r.t){
                      if (this.results.length === 0) {
                          this.results = r.results || [];
                      } else {
                          this.results = this.results.concat(r.results)
                      }
                  }
                  this.isFinished = true;
                }
              }, 
              async viewLease(result) {
                  this.setSecondaryContactId(result.id)
                  this.setSecondContact(result)
              },
          }
      }
  </script>
  
  <style scoped>
      .results-container{
        max-height: 30vh;
        overflow: auto;
      }
      .omni-search-result-item{
          flex: 1;
          overflow-y: auto;
      }
      .omni-search-result-container{
          display: flex;
          flex-direction: column;
          height: 80vh;
          overflow-y: hidden;
      }
  
      .contact-space-pointer {
          cursor: pointer;
      }
      .result-item-border {
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.12);
        min-width: -webkit-fill-available;
      }
      .result-item:hover {
          background: #fbfbfb;
          cursor: pointer;
      }
      ul.omni-search-category {
          list-style:none;
          padding: 0;
          margin:0;
          background: #f5f7f8;
          border-top: 1px solid #dce8ef;
          border-bottom: 1px solid #dce8ef;
      }
      ul.omni-search-category li:hover,
      ul.omni-search-category li.selected {
          background: #e6eaec;
      }
  
      ul.omni-search-category li {
          background: #f0f0f5;
          float: left;
          padding: 10px 20px;
          color: #00b2ce;
          border-right: 2px solid #dce8ef;
          font-weight: 300;
          cursor: pointer;
      }
  
      ul.omni-search-results{
          list-style:none;
          padding: 0;
          margin:0;
          background: white;
      }
  
      ul.omni-search-results li{
          padding: 13px 20px;
          font-weight: 300;
          cursor: pointer;
          background: white;
          border-bottom: 1px solid #dce8ef;
      }
  
      ul.omni-search-results li:hover{
          background: #f2f9fd;
      }
  
      .omni-search-field{
          margin-bottom: auto !important;
      }
  
      .omni-search-field .icon{
          position: absolute;
          font-size: 14px;
          /* color: #e2e2e2; */
          color: #6f6767;
          top: 16px;
          left: 20px;
      }
  
      .omni-search-field{
          border: 0;
          margin: 0;
          height: auto;
      }
      .searchbox{
          outline: none;
          border: 0;
          padding: 0px;
          margin: 0;
          height: 40px;
          color: #101318;
      }
      .noscroll{
          position: fixed;
          overflow: hidden;
      }
  
  
      .omni-search-container {
          position: relative;
          width: auto;
          padding: 0;
          background-color: #fff;
          border-radius: 2px;
          transition: all .3s ease;
          position: relative;
          -webkit-background-clip: padding-box;
          background-clip: padding-box;
          outline: 0;
  
      }
  
      .omni-search-header h3 {
          margin-top: 0;
          color: #00b2ce;
      }
  
      .omni-search-enter .omni-search-container,
      .omni-search-leave-active .omni-search-container {
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
      }
  
      .omni-search-container.modal-blank{
          padding:0;
          box-shadow: none;
      }
  
      .omni-search-sheet {
          color: #928d8d;
          margin-left: 6px;
          font-size: 14px;
          padding-top: 2px;
      }
      .omni-search-underline-text {
          color: #928d8d;
      }
      .border-radius-zero{
          border-radius: 0px;
      }
      .highlighted-text {
          color: #306FB6;
      }
  
      .text-heading {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #101318;
      }
  
      @media (max-width: 600px) {
  
          .omni-search-container {
              max-width: 500px;
              margin: auto;
              border-radius: 2px;
  
          }
      }
      @media (min-width: 600px) {
          .omni-search-container {
              max-width: 500px;
              margin: 5px !important;
          }
      }
      @media (min-width: 992px) {
          .omni-search-lg {
              width: 800px;
          }
          .omni-search-padding {
              padding: 15px;
          }
      }
  
      .clearfix:before,
      .clearfix:after,
      .omni-search-header:before,
      .omni-search-header:after,
      .omni-search-footer:before,
      .omni-search-footer:after {
          content: " ";
          display: table;
      }
      .clearfix:after,
      .omni-search-header:after,
      .omni-search-footer:after {
          clear: both;
      }
  
      .search-result-list {
          position: absolute;
          background: white;
          max-height: 300px;
          overflow: auto;
          max-width: 450px;
          top: 90px;
      }
  
  </style>
  