<template>
  <div>
    <hb-modal v-model="dialog" show-help-link v-if="dialog" size="large" title="Link Contacts" @close="close">
      <template v-slot:sticky>
        <hb-stepper v-model="stepper">
          <hb-stepper-header>
            <hb-stepper-step
              @click="goToStep(1)"
              :complete="steps[0].status == 'step-complete'"
              :rules="[() => steps[0].status !== 'step-error']"
              key="1-step"
              step="1"
            >
              Select Contacts
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step 
              @click="goToStep(2)"
              :complete="steps[1].status == 'step-complete'"
              :rules="[() => steps[1].status !== 'step-error']"
              key="2-step" 
              step="2"
            >
              Confirm Link
            </hb-stepper-step>

          </hb-stepper-header>
        </hb-stepper>
      </template>
      <template v-slot:subheader>
        <div v-if="stepper === 1">
          Search for and select a contact to link together, then set one as the primary contact. All additional information will be condensed and added to the primary contact’s information. 
        </div>
        <div v-else class="hb-text-night">
          <p>Now that you’ve selected a primary contact, this is what you need to know:</p>
          <ul style="list-style: outside;">
            <li>The contact information attributed to the primary contact will be used for all spaces linked to this tenant.</li>
            <li>All additional contacts linked to this space will be tagged as “additional” so you know what contact information is being used.</li>
            <li>All the documents will be available in the document section of the tenant profile. If you wish to get the new document signed, then please generate the new document and send it to the tenant for signing.</li>
            <li>The access code will be based off the primary contact.</li>
            <li>The saved cards and ACH information for both the spaces will be available in the payment methods section.</li>
          </ul>
        </div>
      </template>
      <template v-slot:content>
        <v-row
          v-if="loading"
          style="height: 500px;"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <hb-card class="card-div" v-else-if="stepper === 1">
          <v-card-text class="card-text contact_text">
            <div class="wrapper">
              <div class="first-contact-content">
                <v-row class="enter-title-text" no-gutters>
                  <v-col>
                    <p>Contact Details</p>
                  </v-col>
                </v-row>
                <v-row justify="space-between">
                  <v-col cols="8" class="no-padding">
                    <mini-profile-view 
                      v-if="firstContact?.id"
                      :contact-id="firstContact.id"
                      :use-contact="false"
                      :contact-data="firstContact"
                      class="pt-1 px-1"
                    />
                  </v-col>
                  <v-col class="pt-2">
                    <hb-radio-group v-model="primaryContact">
                      <hb-radio :value="firstContact.id">
                        <template v-slot:label>
                          <div>Set as Primary Contact</div>
                        </template>
                      </hb-radio>
                    </hb-radio-group>
                  </v-col>
                </v-row>
              </div>
              <div class="second-contact-content">
                <v-row class="enter-title-text" no-gutters>
                  <v-col>
                    <p>Contact to be Linked <span class="hb-font-caption-medium">*Required</span></p>
                  </v-col>
                </v-row>

                <span v-if="secondContact">
                  <v-row justify="space-between">
                    <v-col cols="8" class="no-padding">
                      <mini-profile-view 
                        v-if="secondContact?.id"
                        :contact-id="secondContact.id"
                        :use-contact="false"
                        :contact-data="secondContact"
                        class="pt-1 px-1"
                      />
                    </v-col>

                    <v-col class="pt-2">
                      <hb-radio-group v-model="primaryContact">
                        <hb-radio :value="secondContact.id">
                          <template v-slot:label>
                            <div>Set as Primary Contact</div>
                          </template>
                        </hb-radio>
                      </hb-radio-group>

                      <p class="change-tenant-text" @click="changeTenantFn">
                        Change Tenant
                      </p>
                    </v-col>
                  </v-row>
                </span>
                <span v-else>
                  <v-row justify="space-between">
                    <LinkContactOmniSearch
                      :inputSearchError="inputSearchError"
                      @changeText="updateSearchText"
                      style="overflow-y: hidden; margin-top: auto;"
                      :contact="firstContact"
                      :currentProperty="currentProperty"
                    />
                  </v-row>
                </span>
              </div>
            </div>    
          </v-card-text>
        </hb-card>
        <hb-card class="card-div" v-else-if="stepper === 2">
          <div class="card-text contact_text">
            <div class="wrapper">
              <div class="first-contact-content">
                <v-row class="enter-title-text" no-gutters>
                  <v-col>
                    <p>Contacts to be Linked</p>
                  </v-col>
                </v-row>

                <v-row justify="space-between" v-if="firstContact">
                  <v-col cols="9" class="no-padding">
                    <mini-profile-view 
                      v-if="firstContact?.id"
                      :contact-id="firstContact.id"
                      :use-contact="false"
                      :contact-data="firstContact"
                      class="pt-1 px-1"
                    />
                  </v-col>
                  <v-col class="pt-1" v-if="firstContact.id === primary ">
                    <HbIcon
                      mdi-code="mdi-checkbox-blank-circle"
                      small
                      color="green"
                    />
                    <span class="hb-font-caption-medium pl-1">Primary Contact</span>
                  </v-col>
                </v-row>
              </div>

              <div class="second-contact-content" v-if="secondContact">
                <v-row justify="space-between">
                  <v-col cols="9" class="no-padding">
                    <mini-profile-view 
                      v-if="secondContact?.id"
                      :contact-id="secondContact.id"
                      :use-contact="false"
                      :contact-data="secondContact"
                      class="pt-1 px-1"
                    />
                  </v-col>
                  <v-col class="pt-1" v-if="secondContact.id === primary ">
                    <HbIcon
                      mdi-code="mdi-checkbox-blank-circle"
                      small
                      color="green"
                    />
                    <span class="hb-font-caption-medium pl-1">Primary Contact</span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>  
        </hb-card>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="primary" text @click="next" :disabled="loading">
          {{buttonText}}
        </hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import { notificationMixin } from '@/mixins/notificationMixin.js';
import { parsePhoneNumber } from "libphonenumber-js";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from '../../../EventBus';
import LinkContactOmniSearch from "./LinkContactOmniSearch";
import ContactDetails from './ContactDetails.vue';
import HbCard from '../../../aviary/HbCard.vue';
import MiniProfileView from '../../includes/LeadIntake/MiniProfileView.vue';

export default {
  name: "LinkContact",
  props: ["showLinkContactDialog"],
  mixins:[notificationMixin],
  computed: {
    ...mapGetters({
      firstContact: "contactStore/getFirstContact",
      secondContact: "contactStore/getSecondContact",
      currentProperty: "contactStore/getCurrentProperty",
      primary: "contactStore/getPrimaryContactId",
      secondary: "contactStore/getSecondaryContactId",
    }),
    balanceDue() {
      if (this.firstContact.Leases) {
        let currentProperty = this.currentProperty;
        return (
          Math.round(
            this.firstContact.Leases.reduce(function (a, b) {
              return b.Unit.property_id === currentProperty
                ? a + b.open_balance
                : a + 0;
            }, 0) * 1e2
          ) / 1e2
        );
      }
    },
    buttonText(){
      if(this.stepper === 1){
        return "Next"
      } else if(this.stepper === 2){
        return "Link Contacts"
      }
    }
  },
  created() {
    this.primaryContact = this.firstContact.id;
  },
  data() {
    return {
      dialog: true,
      primaryContact: null,
      searchText: '',
      inputSearchError: false,
      loading: false,
      stepper: 1,
      maxActiveStep: 1,
      steps: [{
        number: 1,
        status: 'step-active' 
      }, 
      {
        number: 2,
        status: 'step-disabled'
      }],
    };
  },
  components: {
    LinkContactOmniSearch,
    ContactDetails,
    HbCard,
    MiniProfileView,
  },
  methods: {
    ...mapMutations({
      setPrimaryContactId: "contactStore/setPrimaryContactId",
      setSecondaryContactId: "contactStore/setSecondaryContactId",
      setSecondContact: "contactStore/setSecondContact",
      setRefreshTenantProfile: 'tenantProfile/setRefreshTenantProfile',
    }),
    ...mapActions({
      linkContacts: "contactStore/linkContactsOnBackend",
      verifyContactProperties: "contactStore/verifyContactProperties"
    }),
    close() {
      this.dialog = false;
      this.$emit("closeDialog");
      this.setSecondContact(null);
      this.setPrimaryContactId(this.firstContact.id);
      this.setSecondaryContactId(null)
    },
    updateSearchText(value) {
      this.searchText = value;
      this.inputSearchError = false;
      EventBus.$emit('success-error-message-close');
    },
    linkMentionedContact() {
      this.inputSearchError = false
      this.loading = true;
      this.linkContacts()
      .then(() => {
        this.showMessageNotification({type: 'success', description: 'The selected contacts have been linked'});

        if(this.primary === this.firstContact.id) {
          this.setRefreshTenantProfile(true);
        } else {
          this.$router.push(`/contacts/${this.primary}`);
        }
        this.loading = false;
        this.close();
      })
      .catch(error => {
        this.showMessageNotification({type: 'error', description: error});
        this.loading = false;
      })

    },
    async validateRecord(){
      if(!this.searchText || !this.secondContact) {

        this.inputSearchError = true
        this.showMessageNotification({type: 'error', description: 'Select a tenant from the search results.'});

        return;
      }

      this.inputSearchError = false
      this.loading = true
      this.verifyContactProperties().then(() => {
        this.goToStep(this.stepper + 1, true);
        this.loading = false;
      }).catch( error => {
        this.showMessageNotification({type: 'error', description: error});
        this.loading = false;
      });
    },

    changeTenantFn() {
      this.setSecondContact(null);
      this.primaryContact = this.firstContact.id;
      this.setPrimaryContactId(this.firstContact.id);
      this.setSecondaryContactId(null)
    },
    displayContactBalance(balance){
      return balance > 0
    },
    async goToStep(stepNumber, previousStepCompleted) {
      const maxStep = 2;

      // User clicks on next step button (not on stepper)
      if(previousStepCompleted) {
        this.steps[stepNumber - 1].status = 'step-active'; 
        this.steps[stepNumber - 2].status = 'step-complete';
        this.stepper = stepNumber;
        this.maxActiveStep = stepNumber;
      } else {
        if(stepNumber === 1 || stepNumber <= this.maxActiveStep) {
          this.stepper = stepNumber;
          this.steps[stepNumber - 1].status = 'step-active'; 

          for(let i = stepNumber + 1; i <= this.maxActiveStep; i++) {
            this.steps[i - 1].status = 'step-disabled'; 
          }

          for(let j = stepNumber - 1; j > 0; j--) {
            this.steps[j - 1].status = 'step-complete'; 
          }
        } 
      }
    },
    async next () {
      if (this.stepper == 1) {
        await this.validateRecord();
      } else if (this.stepper == 2) {
        await this.linkMentionedContact();
      }
    }
  },
  filters: {
    formatPhoneHeaderInfo(value) {
      if (!value) return "";
      try {
        let parsedPhoneNumber = parsePhoneNumber("+" + value);

        if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
          if (parsedPhoneNumber.country === "US") {
            return "+1 " + parsedPhoneNumber.formatNational();
          } else {
            return parsedPhoneNumber.formatInternational();
          }
        } else {
          value = value.toString();

          let numbers = value.replace(/\D/g, ""),
            char = { 0: "(", 3: ") ", 6: "-" };
          value = "";
          for (let i = 0; i < numbers.length; i++) {
            value += (char[i] || "") + numbers[i];
          }
        }
      } catch (err) {
        console.log(err);
      }
      return value;
    },
  },
  watch: {
    primaryContact(newValue, oldValue) {
      this.setPrimaryContactId(newValue);
      this.setSecondaryContactId(oldValue)
    },
    secondContact(newValue, oldValue){
      this.maxActiveStep = 1;
      this.steps[1].status = 'step-disabled';
    }
  },
};
</script>

<style scoped lang="scss">
.card-div {
  background: #F9FAFB;
}

.title {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101318;
  margin-bottom: 0px;
}

.content {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #637381;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.contact_text{
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  display: block !important;
}
.contact_text .content-row{
  padding: 0;
  margin: 0;
}
.content-row { 
  padding: 10px 0px;
}
.content-row .content{
    margin-top:0px;
    font-family: 'Graphik Web';
    color: #637381;
    padding: 15px 24px;
    border-bottom: 1px solid #DFE3E8;
}

.enter-title-div {
  margin-top: 24px;
}

.enter-title-text {
  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #101318;
  margin: 15px 0px -11px 3px !important;
}

.card-text {
  width: 720px;
  height: 480px;
  /* overflow-y: scroll; */
  background: #f9fafb !important;
}

.first-contact-title {
  display: inline-block;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
}

.no-padding {
  padding: 0px !important;
}

.inline {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #637381;
  padding-left: 15px;
}

.change-tenant-text {
  height: 16px;
  /* font-family: 'Graphik'; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  color: #00848e;
  padding-right: 50px;
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex-direction: column; 
  margin: 0 auto; 
  width: 100%;
  background: #F9FAFB;
  min-height: 475px;
}


.first-contact-content {
  /* background: grey; */
  margin: 0px 30px 0px 25px !important;  
}
.first-contact-content .justify-space-between, .second-contact-content .justify-space-between{
  border: 1px solid #DFE3E8;
  width: 100%;
  margin: auto;
  margin-top: 11px;
  padding: 6px 6px 6px 6px;
  border-radius: 6px;
  background: white;
  position: relative;    
}
.first-contact-content .enter-title-text p{
  font-family: 'Graphik Web' !important;
  font-weight: 500 !important;
}
.second-contact-content {
  /* background: grey; */
  margin: 0px 30px 20px 25px !important;  
}
.second-contact-content .enter-title-text{
  // margin: 0px 0px 0px 3px !important;
  width: auto !important;
}
.second-contact-content .enter-title-text p{
  font-family: 'Graphik Web' !important;
  font-weight: 500 !important;
}

.second-contact-content .enter-title-text span{
  font-family: 'Graphik Web' !important;
  font-weight: 100 !important;
}
</style>
