export default Object.freeze({
  TENANT_PROFILE: {
    TITLE: 'Convert to Individual Profile',
    MODAL_TITLE: 'Convert Profile From Business to Individual',
    DESC: 'Converting profile from business to a individual requires the following information.',
    CONFIRMATION_TEXT: 'Are you sure you want to convert profile from a business to a individual? If so, please ensure a new lease is issued to tenant with updated tenant information.'
  },
  BUSINESS_PROFILE: {
    TITLE: 'Convert to Business Profile',
    MODAL_TITLE: 'Convert Profile From Individual to Business',
    DESC: 'Converting profile from individual to a business requires the following information.',
    CONFIRMATION_TEXT: 'Are you sure you want to convert profile from a individual profile to a business? If so, please ensure a new lease is issued to tenant with updated tenant information.',
    CONFIRMATION_DESC: 'This will delete all of the business information.'
  },
});
