<template>
    <v-card class="hb-elevation-medium hb-border-default">

        <div :style="{ maxHeight: elHeight +'px', overflow: 'auto' }">

            <v-row class="mx-0 py-3" v-if="showCompleted && !filterEvents.length && tasks.length === 0 && pendingMoveIn.length === 0 && !tasksList.length">
                <v-col md="12" class="text-center">
                    <div class="no-task-message"><v-icon class="move-up-icon" size="30" color="#02AD0F">mdi-check</v-icon> Great Job! You’re all done</div>
                </v-col>
            </v-row>
            <div v-else>

                <v-row class="border-bottom-row ma-0" v-for="(task,index) in tasksList" @click.stop="task.event_type.can_dismiss ? markCompeleted(task) : ''" :key="index" :class="{'blurred-row' : (task.todo && task.todo.completed) || markedTasks.includes(task.todo.id), 'hand' : task.event_type.can_dismiss }">
                    <v-col md="2" class="check-item-outer d-flex justify-center mxw-83px">
                        <div class="check-item-circle mx-auto" :class="{'checked' : (task.todo && task.todo.completed) || markedTasks.includes(task.todo.id), 'dashed-border' : !task.event_type.can_dismiss }">
                            <v-icon v-if="task.event_type.can_dismiss" color="#fff">mdi-check</v-icon>
                            <v-tooltip bottom v-else>
                                <template v-slot:activator="{ on }">
                                    <span class="auto-complete-sign" v-on="on">A</span>
                                </template>
                                <span>Auto Complete</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                    <v-col md="10" class="pa-0">
                        <profile-task-item :event="task.event_type" :todo="task.todo" :activeView.sync="activeView" :contact="task.contact" :unit="task.unit" :key="index" :index="index" :lease_id="task.lease_id" :created_date="task.todo && task.todo.original_date ? task.todo.original_date : ''" @HB-TaskItem--action="taskItemAction" :fetchTasks="fetchTasks"></profile-task-item>
                    </v-col>
                </v-row>

                <v-row class="mx-0 border-bottom-row no-border-bottom" v-if="isLoading($options.name)">
                    <v-col md="12" class="text-center">
                        <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
                    </v-col>
                </v-row>

            </div>            
        </div>
        <!--
        <date-range @close="showDateRange = false" v-if="showDateRange" />
        <to-do v-model="showDialog" v-if="showDialog" @close="fetchTasks"></to-do>
        -->

    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProfileTaskItem from '../includes/TaskCenter/ProfileTaskItem';
import ToDo from "../includes/Todo.vue";
import DateRange from '../includes/TaskCenter/DateRange.vue';
import api from '../../assets/api.js';
import { EventBus } from '../../EventBus.js';

export default {
    name: "ContactTasks",
    components: { ToDo, ProfileTaskItem, DateRange },
    props: ['activeView', 'pendingMoveIn', 'contact', 'propertyView'],
    data() {
        return {
            showDialog: false,
            timeRange:[{
                title: "Yoday",
            },{
                title: "Yesterday"
            },{
                title: "This Week"
            },{
                title: "Last Month"
            },{
                title: "All Time"
            },{
                title: "Date Range"
            }],
            markedTasks: [],
            showDateRange: false,
            tempTask: [],
            isScrollLoading: false,
            filterEvents: [],
            showFilters: false,
            tasksList: [],
            showCompleted: false,
            showLoader: false,
            isScrollRequest: false,
            tempFilters: [],
            allowScrollRequest: false
        }
    },
    created() {
        this.resetTasks();
        this.setMoveInTasks();
        EventBus.$on('reservation_made',  this.eventBusActions);
        EventBus.$on('reset_profile_tasks',  this.eventBusActions);
    },
    mounted() {
        this.getTaskData();
    },
    destroyed(){
        EventBus.$off('reservation_made',  this.eventBusActions);
        EventBus.$off('reset_profile_tasks',  this.eventBusActions);
    },
    computed: {
        ...mapGetters({
            tasks:'profileTasksStore/tasks',
            events: 'profileTasksStore/eventTypes'
        }),
        elHeight(){
            let pHeight  = document.documentElement.clientHeight;
            let elHeight = pHeight - 265;
            return elHeight;
        },
        uniqueEvents(){
            let arr = [];
            this.events.forEach((el, index) => {
               let found = arr.find( arrEl => arrEl.name == el.name );
               if (!found) {
                   arr.push( { id: el.id, name: el.name } )
               }
            });
            return arr;
        },
        checkPendingMoveIns(){
            return this.$props.pendingMoveIn && this.$props.pendingMoveIn.length ? this.$props.pendingMoveIn : [];
        }
    },
    methods: {
        ...mapActions({
            getTasks: 'profileTasksStore/getTasks',
            getEventTypes: 'profileTasksStore/getEventTypes',
            resetTasks: 'profileTasksStore/resetTasks'
        }),
        eventBusActions(){
            this.resetTasks();
            this.resetLocalTasks();
            this.setMoveInTasks();
            this.getTaskData();
        },
        markAsRead(index){
            if (this.markedTasks.includes(index)) {
                this.markedTasks.splice(this.markedTasks.indexOf(index), 1);
            } else {
                this.markedTasks.push(index)
            }
        },
        taskItemAction(action){
            
        },

        validateTask(taskData){
            if (taskData && taskData.task_type == 'moveIn') {
                let data = {
                    event_type: taskData,
                    contact: taskData.contact,
                    unit: taskData.unit,
                    todo: {
                        id: taskData.id
                    }
                }
                return data;
            } else {
                let taskObj = {
                    todo: taskData.task
                };
                taskObj.todo.count = taskData.count;
                let task    = taskData.task;

                if (taskData.type === 'group' && taskData.Tasks && taskData.Tasks.length) {
                    taskObj.grouped_tasks = taskData.Tasks;
                }
    
                if (
                    task 
                    && Object.keys(task).length > 0
                    && task.Event
                    && Object.keys(task.Event).length
                    && task.Event.event_type
                    && Object.keys(task.Event.event_type).length) {
                        taskObj.event_type = task.Event.event_type;
                        if (task.Event.Contact && Object.keys(task.Event.Contact).length) {
                            taskObj.contact = task.Event.Contact;
                        }
                        if (task.Event.Lease) {
                            taskObj.unit = task.Event.Lease.Unit;
                            taskObj.lease_id = task.Event.Lease.id;
                            if (!taskObj.contact && task.Event.Lease.Tenants && task.Event.Lease.Tenants.length && task.Event.Lease.Tenants[0].Contact) {
                                taskObj.contact = task.Event.Lease.Tenants[0].Contact;
                            }
                        }
                }
                return ( !taskObj.grouped_tasks && Object.keys(taskObj).length > 0 ) || ( taskObj.grouped_tasks && Object.keys(taskObj).length > 1 ) ? taskObj : null;
            }
        },
        markCompeleted(task){
            if (!task.todo.completed) {
                api.put(this, api.TODOS + '/dismiss', { todos: [task.todo] }, 'none').then( r => {
                    if (this.markedTasks.includes(task.todo.id)) {
                        this.markedTasks.splice(this.markedTasks.indexOf(task.todo.id), 1);
                    } else {
                        this.markedTasks.push(task.todo.id)
                    }
                })
            }
        },
        getTaskData(ids = ''){
            this.tasksList = [];
            this.$emit('disableBtns');
            let offset = 0;
            if (this.filterEvents.length && this.isScrollRequest) {
                offset = (this.tasksList.length);
            } else {
                offset = (this.tasksList.length - this.$props.pendingMoveIn.length);
            }
            let data = {
                id: this.$route.params.contact_id,
                property_id: this.propertyView,
                role_ids: this.rolesIDs
            }
            this.getTasks(data).then(r => {
                
                if (r.tasks.length == 0 && this.tasks.length === 0) {
                    this.showCompleted = true;
                } else {
                    this.showCompleted = false;
                }

                if (r.tasks.length == 0) {
                    this.allowScrollRequest = false;
                } else {
                    this.allowScrollRequest = true;
                }
                this.showLoader = false;
                this.tempTask = [...this.tempTask, ...r.tasks];
                if (this.tempTask && this.tempTask.length) {
                    this.tempTask.forEach( (sTask, index) => {
                        let task = this.validateTask(sTask);
                        if (task) {
                            this.tasksList.push(task);
                        }
                    });
                }
                this.tempTask = [];
                this.$emit('disableBtns');
            });

        },
        applyEvents(){
            this.showFilters = false;
            this.filterEvents = this.tempFilters;
            if (!this.isScrollRequest) {
                this.resetLocalTasks();
            }
            let paramIds = '';
            if (this.filterEvents.length) {
                this.filterEvents.forEach(id => {
                    paramIds = `${paramIds}&event_type_id[]=${id}`;
                });
            }
            if (!paramIds) {
                this.setMoveInTasks();
            }
            this.getTaskData(paramIds);
        },
        setMoveInTasks(){
            let pending = this.$props.pendingMoveIn && this.$props.pendingMoveIn.length ? this.$props.pendingMoveIn : [];
            if (pending.length) {
                this.tempTask = this.tempTask.concat(pending);
            }
        },
        fetchTasks(flag = true){
            this.showDialog = false;
            this.isScrollRequest = false;
            if(flag)  {
                this.getEventTypes('task');
            }
            this.applyEvents();
        },
        resetLocalTasks(){
            this.tempTask = [];
            this.tasksList = [];
        },
        closeFilter(){
            this.showFilters = false;
            this.tempFilters = [];
        }
    },
    watch: {
        '$props.pendingMoveIn'(value){
            let pending = value && value.length ? value : [];
            if (pending.length) {
                this.setMoveInTasks();
                /*
                if (!this.isLoading(this.$options.name)) {
                    this.getTaskData();
                }
                */
            }

        },
        '$route.params.contact_id'(){
            this.resetTasks();
            this.resetLocalTasks();
            this.getTaskData();
        },
        propertyView(){
            this.resetTasks();
            this.resetLocalTasks();
            this.setMoveInTasks();
            this.getTaskData();
        }
    }
}
</script>

<style lang="scss" scoped>
    .border-bottom-row {
        &:not(:first-child) {
            border-top: 1px solid #DFE3E8;
        }
        &:last-child{
            border-bottom: 1px solid #DFE3E8;
        }
    }
    .tasks-counter{
        background-color: #F26500;

        > div{
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 14px;
            color: #fff;

            small {
                font-style: normal;
                font-size: 10px;
                line-height: 16px;
                font-weight: 500;
            }
        }
    }
    .task-calender{
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
    .check-item-outer{
        border-right: 1px solid #C4CDD5;
    }
    .check-item-circle{
        width: 32px;
        height: 32px;
        left: 27px;
        top: 16px;
        border: 1px solid #C4CDD5;
        box-sizing: border-box;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .check-item-circle.checked{
        background-color: #02AD0F;
        z-index: 11;
    }
    .list-title{
        font-weight: 500;
        font-size: 14px;
        line-height: 12px;
        color: #101318;
    }
    .list-title:first-of-type + p {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #637381;
        margin-bottom: 0;
        padding-bottom: 5px;
    }
    .follow-timer{
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #02AD0F;
    }
    .list-links a{
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #306FB6;
    }
    .move-up-icon{
        position: relative;
        top: -3px;
    }
    .move-out-date{
        font-size: 12px;
        line-height: 16px;
        color: #637381;
    }
    .min-h-35px{
        min-height: 35px;
    }
    .blurred-row{
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: #E6F7E7;
            opacity: 0.6;
        }
    }
    .no-task-message{
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101318;
    }
    .no-border-bottom{
        border-bottom: 0px solid !important;
    }
    .mxw-83px{
        max-width: 83px;
    }
    .auto-complete-sign{
        font-size: medium;
        font-weight: 500;
        line-height: 0px;
        color: #C4CDD5;
    }
    .dashed-border{
        border: 1px dashed #C4CDD5 !important;
    }

</style>
