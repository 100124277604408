<template>
  <hb-modal
      :size="unallocated ? 'medium' : 'small'"
      :title="unallocated ? 'Refund Reserve Balance' : 'Refund Prepay Balance'"
      v-model="dialog"
      @close="close"
      show-help-link
    >
        <template v-slot:content>

          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
          <v-card-text class="pa-0 ma-0">
              <v-container class="pa-0 ma-0">
                <v-row class="hb-table-row ma-0 pa-0">
                  <v-col class="hb-table-label py-4 pl-4" cols="5">
                    Refund From
                  </v-col>
                  <v-col class="ma-0 pa-4" cols="7">
                    <v-select
                      :items="payments"
                      v-model="selection"
                      item-value="id"
                      item-text="amt_remaining"
                      return-object
                      name="selection"
                      label="Select"
                      :hide-details="!errors.collect('selection').length"
                      :error-messages="errors.collect('selection')"
                      v-validate="'required'"
                      data-vv-as="selection"
                    >
                      <template v-slot:selection="data">
                        <span class="text-capitalize mr-1" v-if="data.item.method">{{data.item.method}}</span>Payment on {{data.item.created | formatDateServices}}
                      </template>
                      <template v-slot:item="data">
                        <span class="text-capitalize mr-1" v-if="data.item.method">{{data.item.method}}</span>Payment on {{data.item.created | formatDateServices}}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="hb-table-row ma-0 pa-0">
                    <v-col class="hb-table-label py-4 pl-4" cols="5">
                      Reason
                    </v-col>
                    <v-col class="ma-0 pa-4" cols="7">
                      <v-text-field
                        v-model="reason"
                        label="Reason"
                        v-validate="'required'"
                        data-vv-name="reason"
                        name="reason"
                        id="reason"
                        :hide-details="!errors.collect('reason') || !errors.collect('reason').length"
                        :error-messages="errors.collect('reason')"
                        data-vv-as="reason"
                      >
                      </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="hb-table-row ma-0 pa-0">
                    <v-col class="hb-table-label py-4 pl-4" cols="5">
                      Amount
                    </v-col>
                    <v-col class="ma-0 pa-4" cols="7">
                      <v-text-field
                        v-model="amount"
                        label="Refund Amount"
                        v-validate="'required|min_value:.01|max_value:' + selection.amt_remaining"
                        data-vv-name="amount"
                        name="amount"
                        prefix="$"
                        id="amount"
                        :hide-details="!errors.collect('amount') || !errors.collect('amount').length"
                        :error-messages="errors.collect('amount')"
                        data-vv-as="amount"
                      >
                      </v-text-field>
                      <div v-if="selection.amount" class="text-body-2 hb-text-light pt-1">(Total Available: {{ selection.amt_remaining | formatMoney }})</div>
                    </v-col>
                </v-row>

              </v-container>

          </v-card-text>
            
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" @click="refund(selection)" :disabled="!selection || !amount">Refund</hb-btn>
            <loader v-show="isLoading($options.name)" color="#00b2ce" size="20px" class="inline-loader"></loader>
        </template>
    </hb-modal>

</template>
<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import { EventBus } from '../../EventBus.js';
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import moment from 'moment';
    export default {
        name: "RefundDialog",
        data() {
            return {
                selection: [],
                reason: '',
                amount: '',
                message: {
                    type: 'success',
                    message: 'Refund Processed.'
                }
            }
        },
        components:{
            Status,
            Loader
        },
        computed: {
          dialog: {
              get () {
                  return this.value
              },
              set (value) {
                  this.$emit('input', value)
              }
          },
        },
        created(){

        },
        props: ['contact_id', 'property_id', 'value', 'payments', 'unallocated'],
        methods:{
            async refund(selection){

                let status = await this.validate(this);
                if(!status) return;

                let data = {
                  amount: this.amount,
                  reason: this.reason
                }

                let r = await api.post(this, api.PAYMENTS + selection.id + '/refund', data);

                EventBus.$emit('payment_saved');
                this.resetForm();
                this.$emit('notification', this.message);
                await EventBus.$emit('refetch_overview_payments');
                this.close();
            },
            resetForm(){
                    this.selection = '';
                    this.amount = '';
            },
            close(){
                this.errorClear(this.$options.name);
                this.successClear(this.$options.name);
                this.$emit('close');
            }
        },
    }

</script>
