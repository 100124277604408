<template>
    <v-card class="ti-widget" style="height:500px">
        <div class="v-card__header ti-widget__header">
            <v-card-title class="ti-widget__title mb-0 pt-4 pb-0">
                <v-row class="pl-1 mt-0">
                    <v-card
                        flat
                        tile
                        class="px-5 body-2 caption"
                        style="line-height: 16px;max-width:80%;word-break: normal;color:#474F5A;"
                    >
                        <span class="row-title subtitle-1 hb-text-night">Activities</span><br />
                        <!-- Subcopy TBD -->
                    </v-card>
                </v-row>
            </v-card-title>
            <v-row class="ma-0 pa-0">
                <v-col cols="6" class="pt-1 pb-0 px-7">
                    <v-select dense
                        :items="activities"
                        v-model="activitiesSelected"
                    ></v-select>
                </v-col>
                <v-col cols="6" class="pt-1 pb-0 px-7">
                    <v-select dense
                        :items="dates"
                        v-model="datesSelected"
                    ></v-select>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
        <div class="v-card__content ti-widget__content">
            <div v-for="(activity, i) in leaseActivities" :key="'activity' + i">
                <v-row>
                    <v-col cols="7">
                        <v-card-text class="ma-0 pt-2 pl-7 pb-2">
                            <v-row class="row-title ma-0 pa-0 pb-3">
                                {{activity.action}}
                            </v-row>
                            <v-row class="property-link ma-0 pa-0">
                                <v-col cols="1" class="ma-0 pa-0">
                                    <span style="padding:0 6px 0 2px">
                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.125 0.125C0.572715 0.125 0.125 0.572715 0.125 1.125V11.875C0.125 12.4273 0.572715 12.875 1.125 12.875H2.25V5.79167H10.75V12.875H11.875C12.4273 12.875 12.875 12.4273 12.875 11.875V1.125C12.875 0.572715 12.4273 0.125 11.875 0.125H1.125ZM4.375 1.54167H8.625V2.95833H4.375V1.54167ZM3.66667 7.20833H9.33333V8.625H3.66667V7.20833ZM9.33333 10.0417H3.66667V11.4583H9.33333V10.0417Z" fill="#306FB6"/>
                                        </svg>
                                    </span>
                                </v-col>
                                <v-col cols="11" class="ma-0 pa-0 pl-2" style="position:relative;top:-1px;">
                                    #{{lease.Unit.number}}
                                </v-col>
                            </v-row>
                            <v-row class="property-link ma-0 pa-0">
                                <v-col cols="1" class="ma-0 pa-0">
                                    <v-icon color="#306FB6" size="17" class="pr-1">
                                        mdi-account
                                    </v-icon>
                                </v-col>
                                <v-col cols="11" class="ma-0 pa-0 pl-2" style="position:relative;top:1px;">
                                    {{lease.Tenants[0].Contact.first}} {{lease.Tenants[0].Contact.last}} <span v-if="lease.Tenants[0].Contact.Phones.length"> | {{ lease.Tenants[0].Contact.Phones[0].phone | formatPhone }}</span><span v-if="!lease.Tenants[0].Contact.Phones.length && lease.Tenants[0].Contact.email && lease.Tenants[0].Contact.email.length"> | {{ lease.Tenants[0].Contact.email }}</span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-col>
                    <v-col cols="5" class="pt-5 pr-5 gray-color caption">
                        {{activity.date}}
                        <br />
                        {{lease.Tenants[0].Contact.first}} {{lease.Tenants[0].Contact.last}}
                    </v-col>
                </v-row>
                <v-divider v-if="i < leaseActivities.length -1"></v-divider>
            </div>
        </div>
    </v-card>
</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import { EventBus } from '../../EventBus.js';
    import Status from '../includes/Messages.vue';
    import api from '../../assets/api.js';
    import { mapGetters } from 'vuex';

    export default {
        name: "OverviewActivitiesWidget",
        data() {
            return {
                contact_id: '',
                activitiesSelected: 'All Activities',
                activities: ['All Activities'],
                datesSelected: 'All Dates',
                dates: ['All Dates'],
                leaseActivities: [{"date" : "Apr 19, 2020 | 7:00PM", "action" : "Invoice Paid"}, {"date" : "Mar 26, 2020 | 2:00PM", "action" : "Invoice Paid"}, {"date" : "Feb 24, 2020 | 12:00PM", "action" : "Move-In"}]
            }
        },
        components:{
            Modal,
            Status,
        },
        props: ['lease'],
        watch: {
            'lease': function(){
                // must reload created method api calls here on lease change
            }
        }
    }
</script>
<style scoped>

    .row-title{
        font-weight: 500;
    }

    .gray-color{
        color:#637381;
    }

    .property-link{
        color:#306FB6;
        text-decoration:none;
    }

</style>