<template>
  <div>
    <hb-modal v-model="dialog" show-help-link size="large" title="Unlink Space" @close="$emit('close')">
      <template v-slot:sticky>
      <hb-stepper v-model="stepper">
        <hb-stepper-header>
          <hb-stepper-step
            @click="goToStep(1)"
            :complete="steps[0].status == 'step-complete'"
            :rules="[() => steps[0].status !== 'step-error']"
            key="1-step"
            step="1"
          >
            Choose Space
          </hb-stepper-step>

          <v-divider></v-divider>

          <hb-stepper-step 
            @click="goToStep(2)"
            :complete="steps[1].status == 'step-complete'"
            :rules="[() => steps[1].status !== 'step-error']"
            key="2-step" 
            step="2"
          >
            Access Code
          </hb-stepper-step>

          <v-divider></v-divider>

          <hb-stepper-step 
            @click="goToStep(3)"
            :complete="steps[1].status == 'step-complete'"
            :rules="[() => steps[1].status !== 'step-error']"
            key="3-step" 
            step="3"
          >
            Confirm Unlink
          </hb-stepper-step>

        </hb-stepper-header>
      </hb-stepper>
    </template>
      <template v-slot:subheader>
        {{subheaderDescription}}
      </template>
      <template v-slot:content>
        <v-row
          v-if="loading"
          style="height: 500px;"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular
              indeterminate
              color="primary"
          ></v-progress-circular>
        </v-row>
        <span v-show="stepper === 1 && !loading">
          <div class="wrapper">
            <div class="contact-content">
              <v-row class="enter-title-text mt-3">
                <p>Contact Details</p>
              </v-row>
              <v-row justify="space-between" class="px-4 py-3">
                <mini-profile-view 
                  :contact-id="contact.id"
                  :use-contact="false"
                  :contact-data="contact">
                </mini-profile-view>
              </v-row>
              <v-row class="enter-title-text mt-3">
                <p>Space to be Unlinked <span class="hb-font-caption-medium">*Required</span></p>
              </v-row>
              <v-row justify="space-between" class="px-4 py-3">
                <div style="width:580px;">
                  <p class="hb-font-body-medium"><hb-icon mdi-code="mdi-link-variant" color="#101318"></hb-icon> Select a space to unlink.</p>
                  <hb-select
                    style="font-size: 14px;"
                    v-model="selectedLease"
                    hide-details
                    :items="propertyLeases"
                    item-value="id"
                    item-text="Select a Space"
                    :error="selectedError"
                    label="Select a Space"
                    dense
                  >
                    <template v-slot:selection="data" >
                      <HbUnitIcon small :type="getUnitTypeLabel(data.item.Unit)" :applyStroke="false" :color="selectedError ? '#FB4C4C' : '#101318'" />
                   
                      <span class="pl-1">
                        {{ data.item.Unit.number }} | <span v-if="data.item.Unit.Property.number">{{data.item.Unit.Property.number}} - </span>{{data.item.Unit.Property.Address.city}} - {{data.item.Unit.Property.Address.address}}
                      </span>
                    </template>

                    <template v-slot:item="data" >
                      <HbUnitIcon small :type="getUnitTypeLabel(data.item.Unit)" :applyStroke="false" color="#101318" />
                   
                      <span class="pl-1">
                        {{ data.item.Unit.number }} | <span v-if="data.item.Unit.Property.number">{{data.item.Unit.Property.number}} - </span>{{data.item.Unit.Property.Address.city}} - {{data.item.Unit.Property.Address.address}}
                        (Balance {{ data.item.open_balance | formatMoney}})
                      </span>
                    </template>
                  </hb-select>
                </div>
              </v-row>
            </div>
          </div>
        </span>
        <span v-show="stepper === 2 && !loading && lease">
          <div class="wrapper">
            <gate-access
              ref="gateAccess"
              :contact="contact"
              :property_id="currentProperty"
              :lease="lease"
              @onChange="disableNextSteps"
            />
          </div>
        </span>
        <span v-show="stepper === 3 && !loading">
          <div class="wrapper">
            <div class="contact-content py-4">
              <v-row>
                <div class="stepper-warning px-3 py-3">
                  <span><HbIcon color="#FFD600" mdi-code="mdi-alert" /></span>
                  <span class="px-3">Before you unlink this contact from this space, make sure you know that:</span>
                  <div class="px-10">
                    <ul style="list-style: outside;">
                      <li>All information tied to your selection will be copied to the other contacts associated with this space.</li>
                      <li>The exception will be transaction history, as all space specific transactions will be separated out accordingly.</li>
                    </ul>
                  </div>
                </div>
              </v-row>
              <v-row class="enter-title-text mt-3">
                <p>Contact Details</p>
              </v-row>
              <v-row justify="space-between" class="px-4 py-3">
                <mini-profile-view 
                  :contact-id="contact.id"
                  :use-contact="false"
                  :contact-data="contact">
                </mini-profile-view>
              </v-row>
              <v-row class="enter-title-text mt-3">
                <p>Space to be Unlinked</p>
              </v-row>
              <v-row justify="space-between" class="px-4 py-3">
                <mini-profile-view 
                  :contact-id="contact.id"
                  :use-contact="false"
                  :contact-data="contact">
                </mini-profile-view>
              </v-row>
            </div>
          </div>
        </span>
      </template>
      <template v-slot:actions>
        <hb-btn :disabled="loading" color="primary" @click="performUnlinking">{{buttonText}}</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script type="text/babel">
import { notificationMixin } from '@/mixins/notificationMixin.js';
import { mapGetters, mapMutations, mapActions } from "vuex";
import ContactDetails from './ContactDetails.vue';
import GateAccess from './GateAccess.vue';
import MiniProfileView from '../../includes/LeadIntake/MiniProfileView.vue'

export default {
  name: "UnlinkSpace",
  props: ["contact", "value"],
  mixins:[notificationMixin],
  data() {
    return {
      selectedLease: null,
      selectedError: false,
      stepper: 1,
      maxActiveStep: 1,
      lease: null,
      propertyLeases: null,
      loading: false,
      steps: [{
        number: 1,
        status: 'step-active' 
      }, 
      {
        number: 2,
        status: 'step-disabled'
      },
      {
        number: 3,
        status: 'step-disabled'
      }],
    };
  },
  components: {
    ContactDetails,
    GateAccess,
    MiniProfileView
  },
  created() { 
    this.contact.Leases.map( lease => lease.Unit.Property = this.properties.find(p => p.id === lease.Unit.property_id));
    this.propertyLeases = this.contact.Leases.filter(l => l.Unit.property_id === this.currentProperty);
  },
  destroyed() {
    this.resetUnlink();
  },
  computed: {
    ...mapGetters({
      properties: 'propertiesStore/filtered',
      currentProperty: "contactStore/getCurrentProperty",
    }),
    dialog: {
      get () {
          return this.value
      },
      set (value) {
          this.$emit('input', value)
      }
    },
    subheaderDescription(){
      if(this.stepper === 1){
        return "Select the space that you would like to unlink from this contact."
      } else if(this.stepper === 2){
        return "For the spaces to be unlinked, you need to assign a new access code to the unlinked space";
      } else if(this.stepper === 3){
        return "Read carefully before continuing."
      }
    },
    buttonText(){
      if(this.stepper === 1 || this.stepper === 2){
        return "Next"
      } else if(this.stepper === 3){
        return "Unlink Space"
      }
    }
  },
  methods: {
    ...mapMutations({
      setRefreshTenantProfile: 'tenantProfile/setRefreshTenantProfile',
      resetUnlink: 'contactStore/resetUnlink'
    }),
    ...mapActions({
      verifyGateAccess: "contactStore/verifyGateAccess",
      verifyPayment: "contactStore/verifyPayment",
      unLink: "contactStore/unLinkSpace"
    }),
    async unlinkSpace() {
      try {
        this.loading = true;
        await this.unLink({contact_id: this.contact.id, lease: this.lease})
        this.$emit('close');
        this.showMessageNotification({type: 'success', description: 'The linked spaces have been separated. Review the information from each profile to verify all information has separated accurately.'});
        this.setRefreshTenantProfile(true);
      } catch (error) {
        this.showMessageNotification({type: 'error', description: error});
      }
      this.loading = false;
    },
    async validateRecord(){
      if(this.contact.Leases.length > 1 && this.selectedLease){
        try {
          this.loading = true;
          await this.verifyPayment({contact_id: this.contact.id, lease_id: this.selectedLease});
          this.selectedError = false;
          this.loading = false;
          this.goToStep(this.stepper + 1, true);
        } catch (error) {
          this.selectedError = true;
          this.loading = false;
          this.showMessageNotification({type: 'error', description: error});
        }
      } else {
        this.selectedError = true;
        let err_message = this.contact.Leases.length <= 1 && this.selectedLease ? 'Unable to unlink as there is only one space for this tenant' : 'Select a space from the search results.'
        this.showMessageNotification({type: 'error', description: err_message});
      }
    },
    async goToStep(stepNumber, previousStepCompleted) {
      // User clicks on next step button (not on stepper)
      if(previousStepCompleted) {
        this.steps[stepNumber - 1].status = 'step-active'; 
        this.steps[stepNumber - 2].status = 'step-complete';
        this.stepper = stepNumber;
        this.maxActiveStep = stepNumber;
      } else {
        if(stepNumber === 1 || stepNumber <= this.maxActiveStep) {
          this.stepper = stepNumber;
          this.steps[stepNumber - 1].status = 'step-active'; 

          for(let i = stepNumber + 1; i <= this.maxActiveStep; i++) {
            this.steps[i - 1].status = 'step-disabled'; 
          }

          for(let j = stepNumber - 1; j > 0; j--) {
            this.steps[j - 1].status = 'step-complete'; 
          }
        } 
      }
    },
    async disableNextSteps(currentStep){
      this.maxActiveStep = currentStep;
      for(let i = currentStep; i <= this.steps.length; i++) {
        this.steps[i - 1].status = 'step-disabled'; 
      }
    },
    async performUnlinking(){
      if (this.stepper === 1){
        await this.validateRecord();
      } else if(this.stepper === 2){

        const status = await this.$refs.gateAccess.$validator.validateAll(); 
        if(!status) return;
        this.loading = true;
        this.verifyGateAccess({contact_id: this.contact.id, lease: this.lease}).then(() => {
          this.loading = false;
          this.goToStep(this.stepper + 1, true);
        }).catch(error => {
          this.loading = false;
          this.showMessageNotification({type: 'error', description: error});
        });
      } else if (this.stepper === 3){
        await this.unlinkSpace();
      }
      return;
    },
  },
  watch: {
    selectedLease(newValue, oldValue) {
      this.lease = this.contact.Leases.find(l => l.id === newValue)
      this.disableNextSteps(1)
    }
  },
};
</script>

<style scoped lang="scss">

.enter-title-text {
  width: 68px;
  height: 18px;

  font-family: "Graphik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;

  color: #101318;

  flex: none;
  order: 0;
  flex-grow: 0;

  width: auto !important;
}
.mt-24 {
  margin-top: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column; 
  margin: 0 auto; 
  width: 100%;
  background: #F9FAFB;
  min-height: 475px;
}

.contact-content {
  margin: 0px 30px 20px 25px !important;  
}
.contact-content .justify-space-between{
  border: 1px solid #DFE3E8;
  margin-top: 11px;
  padding: 6px 6px 17px 6px;
  border-radius: 6px;
  background: white;    
}
.contact-content .enter-title-text p{
  font-family: 'Graphik Web' !important;
  font-weight: 500 !important;
}

.enter-title-text span{
  font-weight: 400 !important;
}

.stepper-warning{
  border: 1px solid #FFD600;
  border-radius: 3px !important;
  background: #FFF9DA;
}
</style>
