import { render, staticRenderFns } from "./OverviewCommunicationsWidget.vue?vue&type=template&id=716373aa&scoped=true&"
import script from "./OverviewCommunicationsWidget.vue?vue&type=script&lang=js&"
export * from "./OverviewCommunicationsWidget.vue?vue&type=script&lang=js&"
import style0 from "./OverviewCommunicationsWidget.vue?vue&type=style&index=0&id=716373aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "716373aa",
  null
  
)

export default component.exports