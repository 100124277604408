<template>
    <v-card class="ti-widget" style="height:500px">
        <div class="v-card__header ti-widget__header">
            <v-card-title class="ti-widget__title mb-0 pt-4 pb-0">
                <v-row class="mb-2">
                    <v-col cols="10">
                        <v-row class="ml-3">
                            <v-avatar
                                size="53"
                                tile
                            >
                                <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="26.5" cy="26.5" r="25.8947" fill="white" stroke="#DFE3E8" stroke-width="1.21053"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.6667 21.3334C31.6667 24.1879 29.3546 26.5 26.5 26.5C23.6454 26.5 21.3334 24.1879 21.3334 21.3334C21.3334 18.4788 23.6454 16.1667 26.5 16.1667C29.3546 16.1667 31.6667 18.4788 31.6667 21.3334ZM16.1667 34.25C16.1667 30.8142 23.0513 29.0834 26.5 29.0834C29.9488 29.0834 36.8334 30.8142 36.8334 34.25V36.8334H16.1667V34.25Z" fill="#637381"/>
                                </svg>
                            </v-avatar>
                            <v-card
                                flat
                                tile
                                class="property-light-black property-transparent px-5 body-2"
                                v-if="contact"
                                style="line-height: 24px;max-width:80%"
                            >
                                <span class="font-weight-medium headline hb-text-night">{{ contact.Contact.salutation }} {{ contact.Contact.first | capitalize }} {{ contact.Contact.last | capitalize  }} {{ contact.Contact.suffix }}</span>
                                <!--
                                    <v-chip
                                        v-if="lease.Standing"
                                        :color="lease.Standing.color"
                                        outlined
                                        class="mx-2"
                                        small
                                        style="margin-top:-8px;"
                                    >
                                        <v-avatar left>
                                            <v-icon small>mdi-checkbox-blank-circle</v-icon>
                                        </v-avatar>
                                        {{ lease.Standing.name }}
                                    </v-chip>
                                -->
                                <br />
                                <span v-if="contact.Contact.Phones && contact.Contact.Phones.length">{{ contact.Contact.Phones[0].phone | formatPhone }}<br /></span>
                                <a class="property-link" @click.stop="sendMessage = true" v-if="contact.Contact.email && contact.Contact.email.length">{{ contact.Contact.email }}</a>
                            </v-card>
                            <send-message
                                v-model="sendMessage"
                                v-if="sendMessage"
                                :contact="contact.Contact"
                            ></send-message>
                        </v-row>
                    </v-col>
                    <v-col cols="2">

                    </v-col>
                </v-row>
            </v-card-title>
            <v-divider></v-divider>
        </div>
        <div class="v-card__content ti-widget__content">
            <v-card-text class="ma-0 pt-2 pl-7 pb-2">
                <div class="my-12">Communication App TBD</div>
            </v-card-text>
        </div>
    </v-card>
</template>

<script type="text/babel">
    import Modal from '../assets/Modal.vue';
    import SendMessage from '../includes/SendMessage.vue'
    import { EventBus } from '../../EventBus.js';
    import Status from '../includes/Messages.vue';
    import api from '../../assets/api.js';
    import { mapGetters } from 'vuex';

    export default {
        name: "OverviewCommunicationsWidget",
        data() {
            return {
                sendMessage:false,
            }
        },
        components:{
            Modal,
            Status,
            SendMessage,
        },
        /*
        created (){
            // EventBus.$on('lease_edited', () => this.fetchData());
            // EventBus.$on('recordPaymentCanceled', () => this.showMoveOut = false);
        },
        destroyed(){
            // EventBus.$off('lease_edited');
            // EventBus.$off('recordPaymentCanceled', () => this.showMoveOut = false);
        },
        methods: {
//            editLease(){
//                this.selectedLease = JSON.parse(JSON.stringify(this.lease));
//                this.$set(this.selectedLease, 'type', (this.lease.monthly ? "Month to Month": "Fixed Length") );
//                this.showEditLink = true;
//            },
        },
        */
        props: ['lease', 'contact'],
        watch: {
            'lease': function(){
                // must reload created api methods on lease change
            }
        }
    }
</script>
<style scoped>

    .property-link{
        color:#306FB6;
        text-decoration:none;
    }

</style>